import { AppBar, Box, Button, Toolbar } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
	ExitToApp as ExitToAppIcon
} from '@material-ui/icons';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import TDRTheme from './styles/TDRTheme';

import { FirebaseContext } from './context/FirebaseContext';
import { RestaurantContext } from './context/RestaurantContext';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AdminBookingRequests from './components/AdminBookingRequests';
import AdminFeatureFlags from './components/AdminFeatureFlags';
import AdminInvoices from './components/AdminInvoices';
import AdminPricingPolicies from './components/AdminPricingPolicies';
import AdminReservationCreate from './components/AdminReservationCreate';
import AdminReservationDetails from './components/AdminReservationDetails';
import AdminReservations from './components/AdminReservations';
import AdminRestaurants from './components/AdminRestaurants';
import AdminTables from './components/AdminTables';
import { AdminWidgets } from './components/admin-widgets/AdminWidgets';
import LargeGroupNotification from './components/LargeGroupNotification';
import Loading from './components/Loading';
import Login from './components/Login';
import Modals from './components/Modals';
import Reservations from './components/Reservations';
import TableVis from './components/TableVis';
import { MatterportProvider } from './context/MatterportContext';
import config from './common/config';

const AdminApp = () => {
	const { initialized, email } = useContext(FirebaseContext);
	const { restaurants, loading } = useContext(RestaurantContext);
	const history = useHistory();

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'));
	const fixedNavBarHeight = smUp ? 73 : 107;

	return (
		<ThemeProvider theme={TDRTheme}>
			<CssBaseline />
			<Box display='flex' flexDirection='column' width='100%' height='100%'>
				<Box display='flex' flexDirection='column'>
					<AppBar style={{ height: fixedNavBarHeight, width: '100%' }} position='relative'>
						<Toolbar>
							<Box display='flex' flexDirection='row' alignItems='center' width='100%'>
								<Box display='flex' mr='auto'>
									<h2>Admin Console</h2>
								</Box>
								<Box display='flex' flexDirection='row'>
									<Box mr={1}>
										<Button onClick={() => history.push('/')}>Restaurants</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => history.push('/tables')}>Tables</Button>
									</Box>
									<Box>
										<Button onClick={() => {
											history.push('/super-admin-reservations');
										}}>Reservations</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => history.push('/booking-requests')}>Booking Requests</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => history.push('/invoices')}>Invoices</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => history.push('/policies')}>Taxes &amp; Payouts</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => history.push('/feature-flags')}>Feature Flags</Button>
									</Box>

									<Box mr={1}>
										<Button onClick={() => history.push('/tables-vis')}>Preview</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => history.push('/integrations')}>Integrations</Button>
									</Box>
									<Box mr={1}>
										<Button onClick={() => window.open(config.addOnsUrl, '_blank').focus()} >Add Ons<ExitToAppIcon style={{ marginLeft: '4px' }}/> </Button>
									</Box>
								</Box>
							</Box>
						</Toolbar>
					</AppBar>
					<LargeGroupNotification />
				</Box>

				<Box display='flex' width='100%' flexGrow={1}>
					{isNull(email) ? (
						<Login />
					) : (
						(isEmpty(restaurants) || loading || !initialized) ? (
							<Loading />
						) : (
							<Switch>
								<Route path='/' exact component={() => <AdminRestaurants />} />
								<Route
									path='/policies'
									exact
									component={() => <AdminPricingPolicies />}
								/>
								<Route
									path='/tables'
									exact
									component={() => (
										<MatterportProvider>
											<AdminTables />
										</MatterportProvider>
									)}
								/>
								<Route path='/tables-vis' exact component={() => <TableVis />} />

								{/* Temporary (HA) route and component so that we still have access with our previous reservation
									manager while dealing with the growing pains in between. */}
								<Route
									path='/super-admin-reservations'
									exact
									component={() => <AdminReservations />}
								/>
								<Route
									path='/invoices'
									exact
									component={() => <AdminInvoices />}
								/>
								<Route
									path='/booking-requests'
									exact
									component={() => <AdminBookingRequests />}
								/>
								<Route
									path='/reservations'
									exact
									component={() => <Reservations />}
								/>
								<Route
									path='/reservations/create'
									exact
									component={() => <AdminReservationCreate />}
								/>
								<Route
									path='/reservations/:reservationId'
									exact
									component={() => <AdminReservationDetails />}
								/>
								<Route
									path='/feature-flags'
									exact
									component={() => <AdminFeatureFlags />}
								/>
								<Route
									path='/integrations'
									exact
									component={() => <AdminWidgets />}
								/>

								<Route
									path='*'
									exact
									component={() => <div>Page not found.</div>}
								/>
							</Switch>
						)

					)}
					<Modals />
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default AdminApp;
