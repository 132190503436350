import { Box } from '@material-ui/core';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import React from 'react';
import { TDR } from 'tdr-common';
import { timeStr } from '../helpers/timeStr';
import ReservationUpdateHistory from './DisplayHistory';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const ReservationDetails = ({
	reservation,
	restaurant,
	table
}: {
	reservation: TDR.Reservation;
	restaurant: TDR.Restaurant;
	table: TDR.Table;
}) => (
	<Box
		display='flex'
		flexDirection='column'
		flexWrap='wrap'
		className='reservation-details'
		textAlign='left'
	>
		<h3>Guest Information</h3>
		<table className='chunk'>
			<tbody>
				<tr>
					<th>Name:</th>
					<td>{reservation?.fullName}</td>
				</tr>
				<tr>
					<th>Email:</th>
					<td>{reservation?.email}</td>
				</tr>
				<tr>
					<th>Phone Number:</th>
					<td>{formatPhoneNumberIntl(reservation?.phone as string & {__tag: 'E164Number'}) ?? reservation?.phone}</td>
				</tr>

				<tr>
					<th>Dietary Restriction Selection:</th>
					<td>
						{reservation?.dietaryRestrictions?.restrictions?.join(', ') ||
              'none selected'}
					</td>
				</tr>
				{reservation?.dietaryRestrictions?.notes && (
					<tr>
						<th>Dietary Restriction Notes:</th>
						<td>{reservation?.dietaryRestrictions?.notes}</td>
					</tr>
				)}
			</tbody>
		</table>

		<h3>Booking Details</h3>
		<table className='chunk'>
			<tbody>
				<tr>
					<th>Guest Count:</th>
					<td>{reservation?.guests}</td>
				</tr>
				<tr>
					<th>Dine-in Date & Time:</th>
					<td>{timeStr(reservation?.time, reservation?.timezone)}</td>
				</tr>
				<tr>
					<th>Table:</th>
					<td>{`${table?.internalName} (${table?.name})`}</td>
				</tr>
				<tr>
					<th>Price:</th>
					<td>{`$${reservation?.price}`}</td>
				</tr>
				{reservation?.guestNotes.length > 0 ? (
					<>
						<tr>
							<th>Guest Note:</th>
							<td>{reservation.guestNotes}</td>
						</tr>
					</>) : null}
				<tr>
					<th>Occasion:</th>
					<td>{reservation?.occasion}</td>
				</tr>
				<tr>
					<th>Items:</th>
					<td>{reservation.items.filter(i => i.type === 'addon').map(i => `${i.quantity} x ${i.label} @ $${i.price}`).join(', ')}</td>
				</tr>
			</tbody>
		</table>
		<h3>Restaurant Details</h3>
		<table className='chunk'>
			<tbody>
				<tr>
					<th>Restaurant Name:</th>
					<td>{restaurant?.name}</td>
				</tr>
				<tr>
					<th>Restaurant Phone:</th>
					<td>{restaurant.phoneNumber || 'No Phone Configured'}</td>
				</tr>
			</tbody>
		</table>
		<h3>Reservation History</h3>
		{reservation?.updateHistory?.map((update) => (
			<TimelineItem key={String(update.updatedAt)}>
				<TimelineSeparator>
					<TimelineDot />
					<TimelineConnector/>
				</TimelineSeparator>
				<TimelineContent>
					<ReservationUpdateHistory updateHistory={[update]}>
					</ReservationUpdateHistory>
				</TimelineContent>
			</TimelineItem>
		))}

	</Box>
);

export default ReservationDetails;
